import React from 'react';
import { useAuthenticationContext } from '../../../common/authentication/AuthenticationContext';
import style from './UserDetails.module.css';

const UserDetails: React.FC = () => {
  const { firebaseUser } = useAuthenticationContext();
  if (!firebaseUser) {
    return null;
  }

  const { displayName, email } = firebaseUser;
  return (
    <div className={style.userDetails}>
      {displayName && <div className={style.userName}>{displayName}</div>}
      <div className={style.userEmail}>{email}</div>
    </div>
  );
};

export default UserDetails;
