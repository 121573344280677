import UserAccountPermissionsPage from '../pages/UserAccountPermissionsPage';
import RetailAnalyticsAuthPage from '../../../../pages/retailAnalyticsAuth';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';
import { lazyWithRetry } from '../../../lib';
import ManageAccountMembersPage from '../../../../pages/manageAccountMembers';
import SubmitGoogleSheetsMasterDataPage from '../../../../pages/submitGoogleSheetsMasterDataPage';
import TermsOfServiceVersionsPage from '../../../../pages/termsOfServiceVersions';
import { RouteDeclaration, RouteIdToRoute } from '../../components/routing/lib';
import ReportSchedulesPage from '../pages/ReportSchedulesPage';
import BiReportPage from '../pages/BiReportPage';
import MdmPage from '../pages/MdmPage';
import AccountDataCatalogPage from '../pages/AccountDataCatalogPage';
import ConnectorConfigurationsPage from '../pages/ConnectorConfigurationsPage';
import AccountHomePage from '../pages/AccountHomePage';
import EditConnectorConfigurationPage from '../pages/EditConnectorConfigurationPage';
import UnfiInsightsAdminPage from '../../unfiInsightsOnPlatform/pages/unfiInsightsAdminPage';
import UnfiInsightsUserMergePage from '../../unfiInsightsOnPlatform/pages/unfiInsightsUserMergePage';
import UnfiInsightsUserAdminPage from '../../unfiInsightsOnPlatform/pages/unfiInsightsUserAdminPage';
import ConnectorAuditTrailPage from '../pages/ConnectorAuditTrailPage';
import UserInvitationPage from '../pages/UserInvitationPage';
import RetailAnalyticsUserAdminPage from '../pages/RetailAnalyticsUserAdminPage';
import LuminateBasicDataPresencePage from '../pages/LuminateBasicDataPresencePage';
import AnalyticsBlueprintsPage from '../pages/AnalyticsBlueprintsPage';
import AccountSelectPage from '../pages/AccountSelectPage';
import ManagePowerBiBookmarksPage from '../../../../pages/managePowerBiBookmarksPage';
import TermsOfServicePage from '../../../../pages/termsOfService';
import RemaConnectAdminPage from '../../../../pages/remaConnectAdminPage';
import AccountsPage from '../pages/AccountsPage';
import AccountSettingsPage from '../pages/AccountSettingsPage';
import InboundDataStatusPage from '../pages/InboundDataStatusPage';
import { AccountDetailsTab, InboundDataStatusTab, UnfiInsightsUserAdminTab } from './Tabs';

const AddConnectorConfigurationListPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "addConnectorConfigurationList" */ '../../../../pages/addConnectorConfigurationList'
    ),
);

const CreateConnectorConfigurationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "createConnectorConfiguration" */ '../../../../pages/createConnectorConfiguration'
    ),
);
const ForgotPasswordPage = lazyWithRetry(
  () => import(/* webpackChunkName: "forgotPassword" */ '../../../../pages/forgotPassword'),
);
const RemaConnectPage = lazyWithRetry(
  () => import(/* webpackChunkName: "remaConnect" */ '../../../../pages/remaConnect'),
);
const CrispConnectPage = lazyWithRetry(
  () => import(/* webpackChunkName: "crispConnect" */ '../../../../pages/crispConnect'),
);
const SignInPage = lazyWithRetry(
  () => import(/* webpackChunkName: "signIn" */ '../../../../pages/signIn'),
);
const SignOutPage = lazyWithRetry(
  () => import(/* webpackChunkName: "signout" */ '../../../../pages/signout'),
);
const UpdatePasswordPage = lazyWithRetry(
  () => import(/* webpackChunkName: "updatePassword" */ '../../../../pages/updatePassword'),
);

const BusinessDashboardGroupPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "businessDashboardGroup" */ '../../../../pages/businessDashboardGroup'
    ),
);
const OAuth2AuthSuccessPage = lazyWithRetry(
  () => import(/* webpackChunkName: "oauth2AuthSuccess" */ '../../../../pages/oauth2AuthSuccess'),
);
const OAuth2AuthShopifyPage = lazyWithRetry(
  () => import(/* webpackChunkName: "oauth2AuthShopify" */ '../../../../pages/oauth2AuthShopify'),
);

const ManageSourceLabelsPage = lazyWithRetry(
  () => import(/* webpackChunkName: "manageSourceLabels" */ '../../../../pages/manageSourceLabels'),
);

const PlaygroundPage = lazyWithRetry(
  () => import(/* webpackChunkName: "playground" */ '../../../../pages/playground'),
);

const DataCatalogPrintPreviewPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "dataCatalogPrintPreview" */ '../../../../pages/dataCatalogPrintPreview'
    ),
);

const ObservePipelinePage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "observePipelinePage" */ '../../../../pages/observePipelinePage'),
);
const ObserveAccountPipelinePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "observeAccountPipelinePage" */ '../../../../pages/observeAccountPipelinePage'
    ),
);
const AccountMonitorPage = lazyWithRetry(
  () => import(/* webpackChunkName: "accountMonitor" */ '../../../../pages/accountMonitorPage'),
);
const ReportBuilderPage = lazyWithRetry(
  () => import(/* webpackChunkName: "reportBuilder" */ '../../../../pages/reportBuilder'),
);

const RemaRoutes = [
  {
    id: 'RemaConnect',
    path: '/:accountId/rema-connect',
    Render: RemaConnectPage,
  },
  {
    id: 'RemaConnectReport',
    path: '/:accountId/rema-connect/report/:dashboardId?/:bookmarkId?',
    Render: RemaConnectPage,
  },
  {
    id: 'RemaConnectAdminDefault',
    path: '/rema-connect/admin',
    Render: RemaConnectAdminPage,
  },
  {
    id: 'RemaConnectAdminDiagnostics',
    path: '/rema-connect/admin/diagnostics',
    Render: RemaConnectAdminPage,
  },
  {
    id: 'RemaConnectAdminAccounts',
    path: '/rema-connect/admin/accounts',
    Render: RemaConnectAdminPage,
  },
  {
    id: 'RemaConnectUserProvisioningLogs',
    path: '/rema-connect/admin/user-provisioning-logs',
    Render: RemaConnectAdminPage,
  },
] as const satisfies RouteDeclaration[];

// The route declarations will be evaluated in order, specify specific routes first and generic ones
// further towards the end of the list.
export const Routes = [
  {
    id: 'UserAccountPermissions',
    path: '/user-account-permissions',
    Render: UserAccountPermissionsPage,
  },
  {
    id: 'RetailAnalyticsAuth',
    path: '/retail-analytics-auth',
    Render: RetailAnalyticsAuthPage,
    requireAuth: false,
  },
  {
    id: 'UnfiInsightsAdmin',
    path: '/unfi-insights/admin',
    Render: UnfiInsightsAdminPage,
  },
  {
    id: 'Playground',
    path: '/playground',
    Render: PlaygroundPage,
  },
  {
    id: 'AccountSelect',
    path: '/',
    Render: AccountSelectPage,
  },
  {
    id: 'SignIn',
    path: '/login',
    Render: SignInPage,
    requireAuth: false,
  },
  {
    id: 'SignOut',
    path: '/signout',
    Render: SignOutPage,
    requireAuth: false,
  },
  {
    id: 'ResetPassword',
    path: '/login/forgot-password',
    Render: ForgotPasswordPage,
    requireAuth: false,
  },
  {
    id: 'UpdatePassword',
    path: '/login/update-password',
    Render: UpdatePasswordPage,
  },
  {
    id: 'OAuth2AuthSuccess',
    path: '/oauth2-auth-success',
    Render: OAuth2AuthSuccessPage,
  },
  {
    id: 'OAuth2AuthShopify',
    path: '/oauth2-auth-shopify',
    Render: OAuth2AuthShopifyPage,
  },
  {
    id: 'ManageSourceLabels',
    path: '/source-labels',
    Render: ManageSourceLabelsPage,
  },
  {
    id: 'TermsOfServiceVersions',
    path: '/tos-versions',
    Render: TermsOfServiceVersionsPage,
  },
  {
    id: 'Admin',
    path: '/admin',
    Render: AccountsPage,
  },
  {
    id: 'Accounts',
    path: '/accounts',
    Render: AccountsPage,
  },
  {
    id: 'ObservePipeline',
    path: '/observe/:aspect(timeline|data-presence)?',
    Render: ObservePipelinePage,
    requireFeatureFlag: KnownFlags.PipelineObservability,
  },
  {
    id: 'AccountMonitor',
    path: '/account-monitor',
    Render: AccountMonitorPage,
  },
  {
    id: 'DataCatalogPrintPreview',
    path: '/:accountId/catalog-print',
    Render: DataCatalogPrintPreviewPage,
  },
  {
    id: 'AccountDetails',
    path: `/admin/:accountId/:tab(${Object.values(AccountDetailsTab).join('|')})?/:identifier?`,
    Render: AccountSettingsPage,
  },
  {
    id: 'AccountHome',
    path: '/:accountId',
    Render: AccountHomePage,
  },
  {
    id: 'ManageAccountMembers',
    path: '/:accountId/manage-users',
    Render: ManageAccountMembersPage,
  },
  {
    id: 'ViewInvitation',
    path: '/:accountId/invitations/:invitationId/token/:token',
    Render: UserInvitationPage,
    requireAuth: false,
  },
  {
    id: 'ConnectorAuditTrail',
    path: '/:accountId/connectors/:connectorConfigurationSlug/audit-trail',
    Render: ConnectorAuditTrailPage,
  },
  {
    id: 'Connectors',
    path: `/:accountId/connectors`,
    Render: ConnectorConfigurationsPage,
  },
  {
    id: 'AddConnectorConfigurationList',
    path: `/:accountId/connectors/add`,
    Render: AddConnectorConfigurationListPage,
  },
  {
    id: 'AddConnectorConfiguration',
    path: '/:accountId/connectors/add/:connectorId',
    Render: CreateConnectorConfigurationPage,
  },
  {
    id: 'EditConnectorConfiguration',
    path: '/:accountId/connectors/:connectorConfigurationSlug',
    Render: EditConnectorConfigurationPage,
  },
  ...RemaRoutes,
  {
    id: 'CrispConnect',
    path: '/:accountId/crisp-connect',
    Render: CrispConnectPage,
  },
  {
    id: 'CrispConnectReport',
    path: '/:accountId/crisp-connect/report/:dashboardId?/:bookmarkId?',
    Render: CrispConnectPage,
  },
  {
    id: 'BusinessDashboardGroup',
    path: '/:accountId/business-dashboard/:dashboardGroupId?/:dashboardId?/:bookmarkId?',
    Render: BusinessDashboardGroupPage,
  },

  {
    id: 'PowerBiAuthRedirect',
    path: '/powerbi/auth',
    Render: () => null,
    requireAuth: false,
  },
  {
    id: 'ManagePowerBiBookmarks',
    path: '/:accountId/manage-powerbi-bookmarks',
    Render: ManagePowerBiBookmarksPage,
  },
  {
    id: 'SubmitGoogleSheetsMasterData',
    path: '/:accountId/connectors/google-sheets-master-data/:connectorConfigurationSlug/submit',
    Render: SubmitGoogleSheetsMasterDataPage,
  },
  {
    id: 'InboundDataStatus',
    path: `/:accountId/inbound-data-status/:connectorConfigurationSlug?/:tab(${Object.values(
      InboundDataStatusTab,
    ).join('|')})?/:filter?`,
    Render: InboundDataStatusPage,
    requireFeatureFlag: KnownFlags.InboundDataStatus,
  },
  {
    id: 'AccountDataCatalog',
    path: '/:accountId/catalog',
    Render: AccountDataCatalogPage,
  },
  {
    id: 'TermsOfService',
    path: '/:accountId/tos',
    Render: TermsOfServicePage,
  },
  {
    id: 'UnfiInsightsUserMerge',
    path: '/unfi-insights/user-merge/token/:token',
    Render: UnfiInsightsUserMergePage,
  },
  {
    id: 'ObserveAccountPipeline',
    path: '/:accountId/observe/:aspect(timeline|data-presence)?',
    Render: ObserveAccountPipelinePage,
    requireFeatureFlag: KnownFlags.PipelineObservability,
  },
  {
    id: 'ReportBuilder',
    path: '/:accountId/report-builder/:conversationId?',
    Render: ReportBuilderPage,
    requireFeatureFlag: KnownFlags.ReportBuilder,
  },
  {
    id: 'Mdm',
    path: '/:accountId/mdm',
    Render: MdmPage,
    requireFeatureFlag: KnownFlags.MdmPlusPlus,
  },
  {
    id: 'BiReport',
    path: '/:accountId/reports',
    Render: BiReportPage,
    requireFeatureFlag: KnownFlags.PowerBiEmbeddedReports,
  },
  {
    id: 'UnfiInsightsUserAdmin',
    path: `/unfi-insights/admin/users/:tab(${Object.values(UnfiInsightsUserAdminTab).join('|')})?`,
    Render: UnfiInsightsUserAdminPage,
  },
  {
    id: 'ReportSchedules',
    path: '/:accountId/report-schedules',
    Render: ReportSchedulesPage,
    requireFeatureFlag: KnownFlags.ScheduledReports,
  },
  {
    id: 'RetailAnalyticsUserAdmin',
    path: '/:accountId/retail-analytics/manage-users',
    Render: RetailAnalyticsUserAdminPage,
  },
  {
    id: 'LuminateBasicDataPresence',
    path: '/:accountId/observe-luminate-basic',
    Render: LuminateBasicDataPresencePage,
    requireFeatureFlag: KnownFlags.LuminateBasicDataPresence,
  },
  {
    id: 'Blueprints',
    path: '/:accountId/analytics-blueprints',
    Render: AnalyticsBlueprintsPage,
  },
] as const satisfies RouteDeclaration[];

export type RouteId = (typeof Routes)[number]['id'];

export const RouteById = Routes.reduce<RouteIdToRoute<RouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<RouteId>,
);
