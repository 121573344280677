import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import Flex from '../../components/Flex';
import { Spinner, SpinnerSize } from '../../../ui';
import { inProgressLabel } from '../../../lib';
import Button, { ButtonVariant } from '../../components/Button';
import AuthPromptPanel from './AuthPromptPanel';
import usePartnerSignIn from './usePartnerSignIn';

const SignOut: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { signInThenGoTo } = usePartnerSignIn();

  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AuthPromptPanel>
      {loading && (
        <Flex size="S">
          <Spinner size={SpinnerSize.SMALL} />
          <div>{inProgressLabel('Logging out')}</div>
        </Flex>
      )}
      {!loading && (
        <>
          <div>You&apos;ve successfully logged out.</div>
          <Flex size="S">
            <Button variant={ButtonVariant.Secondary} onClick={() => signInThenGoTo('PartnerHome')}>
              Log back in
            </Button>
          </Flex>
        </>
      )}
    </AuthPromptPanel>
  );
};

export default SignOut;
