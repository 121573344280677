import React from 'react';
import CrispLogo from '../../../../assets/clogo.svg';
import DotLogoPng from '../../../../assets/dot-logo.png';
import { PartnerApplication } from '../../components/lib/Account';
import { PartnerLogoName } from '../PartnerSettings';

type PartnerLogoVariant = 'navrail' | 'banner';

const getLogoNameForPartnerApplication = (
  partnerApplication: PartnerApplication,
): PartnerLogoName => {
  if (partnerApplication.startsWith('dot')) {
    return 'dot';
  }

  return 'crisp';
};

const DefaultNavrailStyleDimensions = {
  width: '52px',
  height: '52px',
};

const DefaultBannerStyleDimensions = {
  width: '100px',
  height: '100px',
};

const NavrailLogoStyles: Partial<Record<PartnerLogoName, object>> = {
  dot: DefaultNavrailStyleDimensions,
  crisp: DefaultNavrailStyleDimensions,
};

const BannerLogoStyles: Partial<Record<PartnerLogoName, object>> = {
  dot: DefaultBannerStyleDimensions,
  crisp: DefaultBannerStyleDimensions,
};

const LogoSrc: Record<PartnerLogoName, string> = {
  dot: DotLogoPng,
  crisp: CrispLogo,
};

type PartnerLogoProps = {
  partnerApplication: PartnerApplication;
  variant: PartnerLogoVariant;
};

const PartnerLogo: React.FC<PartnerLogoProps> = ({ variant, partnerApplication }) => {
  const logo = getLogoNameForPartnerApplication(partnerApplication as PartnerApplication);
  const style = (variant === 'navrail' ? NavrailLogoStyles : BannerLogoStyles)[logo];
  return <img src={LogoSrc[logo]} alt="logo" style={style} />;
};

export default PartnerLogo;
