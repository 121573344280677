import { useCallback } from 'react';
import { ConnectorApplication } from '../../../../generated/graphql';
import { PartnerAccount, PartnerApplication } from '../../components/lib/Account';
import { BiPageConfig, getSearchParamsForConfig } from '../../components/BiReport/lib';
import { RecentReport } from '../../components/BiReport/RecentReports/useRecentReports';
import { path } from '../routing/Routes';

export type PartnerAccountIdParams = Pick<PartnerAccount, 'partnerAccountId'>;
export type PartnerApplicationParams = Pick<PartnerAccount, 'partnerApplication'>;

export type PartnerAccountProps = {
  partnerAccount: PartnerAccount;
};

export const PartnerApplicationToConnectorApplication: Record<
  PartnerApplication,
  ConnectorApplication
> = {
  [PartnerApplication.Dot]: ConnectorApplication.Dot,
  [PartnerApplication.DotDemo]: ConnectorApplication.Dot,
};

export const useGetBiPageUrl = (partnerApplication: PartnerApplication) => {
  return useCallback(
    (pageConfig: BiPageConfig) =>
      `${path('PartnerAnalytics')({ partnerApplication })}?${getSearchParamsForConfig(pageConfig)}`,
    [partnerApplication],
  );
};

export const getRecentReportAttributes = ({ workspace, report, page, bookmark }: RecentReport) => ({
  workspaceId: workspace.id,
  workspaceName: workspace.name,
  reportId: report.id,
  reportName: report.name,
  pageName: page?.name,
  bookmarkName: bookmark?.name,
  bookmarkId: bookmark?.id,
});
